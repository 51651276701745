import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';
import LinkMui from 'components/common/Link';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import api from 'constants/api';

import SelectMenu from 'components/common/SelectMenu';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Collapse from 'components/common/Collapse';
import Typography from 'components/common/Typography';
import Tooltip from 'components/common/Tooltip';
import colors from 'constants/colors';
import { LinkIcon, AutorenewIcon } from 'components/icons/mui_icons';
import IconButton from 'components/common/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddressCell from './AddressCell';

import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import ReportingCompanyCell from './ReportingCompanyCell';
import { getUniqueTargets } from './utils';
import { dateTimeReadable } from 'utils/date';
import LoadingButton from '@mui/lab/LoadingButton';
import useAdminCompanyCollection from 'hooks/useCompanyCollection';
import CompleteButton from './CompleteButton';

function Component({ companyId }: { companyId: string | undefined }) {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { data: SectorData } = useQuery({
    queryKey: ['companies-sectors'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/sectors`,
        auth.requestConfig
      );
      return res.data;
    },
  });

  const {
    data,
    refetch: refetchCompanyCollections,
    isFetching: isfFetchingCompanyCollections,
  } = useAdminCompanyCollection(companyId);

  const [selectedCollection, setSelectedCollection] = React.useState({});

  useEffect(() => {
    if (data) {
      setSelectedCollection(data[0]);
    }
  }, [data]);

  const filterCollectionByName = (name: string) => {
    return data.filter((result: any) => result.name === name);
  };

  const [expandedRowIds, setExpandedRowIds] = useState<Set<number>>(new Set());
  const handleExpandClick = (id: number) => {
    setExpandedRowIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 250,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/company/${params.row.company_slug}`}
            target="_blank"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <Tooltip title={params.row.company_slug}>
              <div>{params.row.name}</div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'company_slug',
      headerName: 'Data Services',
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/admin/company/${params.row.company_id}`}
            target="_blank"
            style={{ textDecoration: 'none', color: colors.neutral500 }}
          >
            <Tooltip title={params.row.name}>
              <LinkIcon />
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 200,
      flex: 2,
      renderCell: (params: any) => {
        const isExpanded = expandedRowIds.has(params.row.id);
        return (
          <div>
            {params.row?.country ? (
              <Box key={params.row.id}>
                {/* Main Row */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{params.row.country}</div>
                  <IconButton onClick={() => handleExpandClick(params.row.id)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
                {/* Expanded Content */}
                <AddressCell tableParams={params} isExpanded={isExpanded} />
              </Box>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <LinkMui
            href={
              params.row.website?.startsWith('http')
                ? `${params.row.website}`
                : `https://${params.row.website}`
            }
            target="_blank"
            rel="noopener"
            underline="none"
            style={{
              textDecoration: 'none',
              color: colors.neutral500,
              overflow: 'hidden',
            }}
          >
            <Tooltip title={params.row.website}>
              <div>{params.row.website}</div>
            </Tooltip>
          </LinkMui>
        );
      },
    },
    {
      field: 'lei_code',
      headerName: 'Lei',
      minWidth: 220,
      flex: 1,
      renderCell: (params: any) => {
        const isExpanded = expandedRowIds.has(params.row.id);
        return (
          <div>
            {params.row.lei_code ? (
              <Box key={params.row.id}>
                {/* Main Row */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{params.row.lei_code}</div>
                  <IconButton onClick={() => handleExpandClick(params.row.id)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
                {/* Expanded Content */}
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  Isin
                  <div
                    style={{ textDecoration: 'none', color: colors.neutral500 }}
                  >
                    {params.row.isin}
                  </div>
                </Collapse>
              </Box>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'reporting_company',
      headerName: 'Reporting Company',
      minWidth: 280,
      flex: 3,
      renderCell: (params: any) => {
        const isExpanded = expandedRowIds.has(params.row.id);
        return (
          <div>
            {params.row.reporting_company ? (
              <Box key={params.row.id}>
                {/* Main Row */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{params.row.reporting_company}</div>
                  <IconButton onClick={() => handleExpandClick(params.row.id)}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </div>
                {/* Expanded Content */}
                <ReportingCompanyCell
                  tableParams={params}
                  isExpanded={isExpanded}
                  sectorData={SectorData}
                />
              </Box>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'sector',
      headerName: 'Sector',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'last_screened',
      headerName: 'Last screened',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'latest_cdp_year',
      headerName: 'CDP - Latest year ',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'company_targets',
      headerName: 'Targets',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'company_in_collection_assign_user',
      headerName: 'Meta data',
      minWidth: 180,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Box>
            <div>
              {t('Assigned user:')}
              <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
                {params.row.company_in_collection_assign_user
                  ? params.row.company_in_collection_assign_user
                  : '-'}
              </div>
              {t('Start date:')}
              <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
                {params.row.company_in_collection_screening_start
                  ? dateTimeReadable(
                      params.row.company_in_collection_screening_start
                    )
                  : '-'}
              </div>
              {t('End date:')}
              <div style={{ textDecoration: 'none', color: colors.neutral500 }}>
                {params.row.company_in_collection_screening_end
                  ? dateTimeReadable(
                      params.row.company_in_collection_screening_end
                    )
                  : '-'}
              </div>
            </div>
            {params.row.company_in_collection_screening_start &&
            !params.row.company_in_collection_screening_end ? (
              <CompleteButton
                companyId={companyId}
                recordId={params.row.company_in_collection_record_id}
              />
            ) : null}
          </Box>
        );
      },
    },
  ];

  const rows = (selectedCollection as any)?.collection_records?.map(
    (record: any, index: any) => {
      const company = record.company;
      return {
        id: index,
        company_id: company.id,
        company_slug: company.slug,
        name: company.name,
        country: company.country,
        website: company.website,
        isin: company.isin,
        reporting_company: company.reporting_company_data
          ? company.reporting_company_data.name
          : null,
        reporting_company_data: company.reporting_company_data
          ? company.reporting_company_data
          : null,
        sector: SectorData ? SectorData[company.sector] : null,
        last_screened: company.last_screened,
        latest_cdp_year: company.cdp_summary
          ? company.cdp_summary[0].year
          : null,
        company_targets: getUniqueTargets(company.company_targets),
        lei_code: company.lei_code,
        address: company.address,
        address2: company.address2,
        city: company.city,
        zip_code: company.zip_code,
        company_in_collection_assign_user: record.assigned_user,
        company_in_collection_screening_start: record.screening_start_datetime,
        company_in_collection_screening_end: record.screening_end_datetime,
        company_in_collection_record_id: record.id,
      };
    }
  );

  return data && data.length > 0 ? (
    <Stack
      spacing={{ xs: 2, sm: 3 }}
      sx={{
        width: { xs: 'calc(100% - 65px)', lg: '100%' },
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontSize: '24px', color: colors.neutral400 }}
      >
        Company Collections
      </Typography>
      <Box width={'100%'}>
        <Typography variant="h6">Please Choose a Collection</Typography>
        <Stack spacing={2} direction={'row'}>
          <SelectMenu
            items={data.map((item: any) => item.name)}
            handleChange={(e: any) => {
              setSelectedCollection(filterCollectionByName(e.target.value)[0]);
            }}
            value={(selectedCollection as any)?.name}
          />
          <LoadingButton
            startIcon={<AutorenewIcon />}
            loading={isfFetchingCompanyCollections}
            loadingPosition="start"
            variant="contained"
            color="inherit"
            onClick={() => {
              refetchCompanyCollections();
            }}
          >
            {t('Reload Collection')}
          </LoadingButton>
        </Stack>
      </Box>

      <Box sx={{ height: 640, width: '100%' }}>
        <Typography variant="h6">Companies in collection</Typography>
        {rows ? (
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            getRowHeight={() => 'auto'}
          />
        ) : null}
      </Box>
    </Stack>
  ) : (
    <div>this company has no collections</div>
  );
}

export default observer(Component);
