// import React from 'react'
import { observer } from 'mobx-react-lite';

import Container from 'components/common/Container';
import { scrollToTop } from 'utils/browser';
import ScrollToTop from 'components/common/ScrollToTop';
// import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';

import CompanyOverview from './Overview';

// import useAuth from 'hooks/useAuth'

function Component() {
  // const auth = useAuth()
  // const navigate = useNavigate()
  // const { t } = useTranslation('common');
  return (
    <Container pageContainer maxWidth="lg" sx={{ pt: 3 }}>
      <CompanyOverview />
      <ScrollToTop />
    </Container>
  );
}

export default observer(Component);
