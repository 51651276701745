import { Alert, Grid, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import Typography from 'components/common/Typography/Typography';
import { useTranslation } from 'react-i18next';
import SourceIcon from '@mui/icons-material/Logout';
import TargetIcon from '@mui/icons-material/Adjust';
import colors from 'constants/colors';
import axios from 'axios';
import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import EditCompanyDetails from 'components/App/Routes/Admin/Company/EditCompanyDetails';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { scrollToTop } from 'utils/browser';
import Container from 'components/common/Container';
import MergeIcon from '@mui/icons-material/Merge';

import useMergeStore from 'components/App/Routes/Admin/Merge/store/useStore';
import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import React from 'react';
import CompanySelect from 'components/common/CompanySelect';
import Fab from '@mui/material/Fab';

const Component = () => {
  const { t } = useTranslation('common');
  const snackbarMessage = useSnackbarMessage();
  const auth = useAuth();
  const { setSource, setTarget, source, target } = useMergeStore();
  const { source: sourceParam, target: targetParam } = useParams();

  React.useEffect(() => {
    if (sourceParam) {
      setSource(sourceParam);
    }
    if (targetParam) {
      setTarget(targetParam);
    }
  }, [setSource, setTarget, sourceParam, targetParam]);

  const { setInvalid } = useSearchStore();

  const { error, mutate, isLoading, reset } = useMutation({
    onSuccess: (res) => {
      setSource('');
      setTarget('');
      scrollToTop();
      setInvalid(true);
      snackbarMessage.addMessage({
        message: res.data?.details || t('Success'),
      });
    },
    mutationFn: async () => {
      const data = new FormData();
      data.set('source', source);
      data.set('target', target);
      const res = await axios.post(
        `${api.url}/admin/companies/merge`,
        data,
        auth.requestConfig
      );
      return res;
    },
  });

  return (
    <Container pageContainer maxWidth="md">
      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          <Typography component="span" variant="h4">
            {t('Merge')}
          </Typography>
          {target || source ? (
            <IconButton
              sx={{ ml: 1 }}
              size="small"
              onClick={() => {
                setSource(target);
                setTarget(source);
              }}
            >
              <SwapHorizIcon />
            </IconButton>
          ) : null}
        </Grid>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error">
              {
                //@ts-ignore
                error.response.data.details || t('Something went wrong.')
              }
            </Alert>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6}>
          <Typography
            variant="caption"
            sx={{
              color: colors.neutral400,
            }}
          >
            {t('Source')}
          </Typography>
          <Typography
            display="flex"
            variant={'h8'}
            sx={{
              color: colors.neutral400,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <SourceIcon sx={{ mr: 1 }} />
            {t('Merge this one')}
          </Typography>

          <CompanySelect
            value={source}
            onChange={(value) => {
              reset();
              setSource(value);
            }}
            placeholder={t('Select source company')}
          />
          {source ? <EditCompanyDetails id={source} /> : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="caption"
            sx={{
              color: colors.primary500,
            }}
          >
            {t('Target')}
          </Typography>
          <Typography
            color={'primary'}
            display="flex"
            variant={'h8'}
            alignItems="center"
          >
            <TargetIcon sx={{ mr: 1 }} />
            {t('Keep this one')}
          </Typography>
          <CompanySelect
            value={target}
            onChange={(value) => {
              reset();
              setTarget(value);
            }}
            placeholder={t('Select target company')}
          />
          {target ? <EditCompanyDetails id={target} diffId={source} /> : null}
        </Grid>
      </Grid>
      <Fab
        sx={{
          margin: 0,
          top: 'auto',
          right: { xs: '16px', sm: '32px' },
          bottom: { xs: '16px', sm: '32px' },
          left: 'auto',
          position: 'fixed',
        }}
        variant="extended"
        disabled={!source || !target || isLoading}
        color="primary"
        onClick={() => {
          mutate();
          scrollToTop();
        }}
      >
        <MergeIcon sx={{ mr: 1 }} />
        {t('Merge')}
      </Fab>
    </Container>
  );
};

export default observer(Component);
